import React, { useEffect } from "react"
import Typed from "typed.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { useIntl, Link } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Register from "../components/register"

const IndexPage = () => {
  const intl = useIntl()

  useEffect(() => {
    new Typed(".typed_values_it", {
      strings:
        intl.locale === "it"
          ? [
              "Collabora e condividi in sicurezza",
              "Coinvolgi i tuoi Clienti",
              "Esprimi il tuo stile",
            ]
          : [
              "Share and collaborate securely",
              "Engage with your clients",
              "Enhance your brand",
            ],
      typeSpeed: 40,
      backSpeed: 20,
      loop: true,
      backDelay: 2000,
      smartBackspace: false,
    })
  }, [])

  return (
    <Layout>
      <SEO title="Home" />
      <div id="top" className="hero-cover relative" style={{ height: "75vh" }}>
        <h1
          className="absolute w-100 z-1 playfair f2 f1-m f-subheadline-l fw4 tc white"
          style={{ top: "25%" }}
        >
          {intl.formatMessage({ id: "landing.title1" })}
          <br />
          {intl.formatMessage({ id: "landing.title2" })}
        </h1>
        <div className="absolute w-100" style={{ top: "66%" }}>
          <h2 className="relative z-1 typed_values_it h2 frank f3 f2-m f1-l fw3 tc white"></h2>
          <h2 className="dn">
            {intl.formatMessage({ id: "landing.tagline1" })}
          </h2>
          <h2 className="dn">
            {intl.formatMessage({ id: "landing.tagline2" })}
          </h2>
          <h2 className="dn">
            {intl.formatMessage({ id: "landing.tagline3" })}
          </h2>
        </div>
      </div>
      <div className="flex flex-rows justify-around pa4-l bg-light-color">
        <div className="w-100 w-50-l pv3-m">
          <Register />
        </div>
        <div className="dn db-l pl4 w-40 bl b--black">
          <span className="db fw4 f3 mt0 mb3 playfair">
            Hai già un account?
          </span>
          <a
            href="https://app.time2client.com/member/session/new"
            className="frank f3 fw3 mv2 br2 bg-sec-color ph4 pv3 white hover-white hover-bg-prim-color db shadow-5"
            style={{ width: "18rem" }}
            rel="nofollow"
          >
            {intl.formatMessage({ id: "landing.loginLawyer" })}
          </a>
          <Link
            to="/search"
            className="frank f3 fw3 mv2 br2 bg-sec-color ph4 pv3 white hover-white hover-bg-prim-color db shadow-5"
            style={{ width: "18rem" }}
            rel="nofollow"
          >
            {intl.formatMessage({ id: "landing.loginClient" })}
          </Link>
        </div>
      </div>
      <div className="relative business-cover">
        <div className="flex mh3">
          <div className="mw7 center pb6-l z-1">
            <h3 className="dib white frank ttu fw4 f4 ph0 mv4 tracked-mega">
              {intl.formatMessage({ id: "landing.brandingTitle" })}
            </h3>
            <h2 className="mv2 white playfair fw4 f2 ph0 lh-copy">
              {intl.formatMessage({ id: "landing.brandingSubtitle1" })}
              <br />
              {intl.formatMessage({ id: "landing.brandingSubtitle2" })}
            </h2>
            <p className="white playfair f3 mv4 lh-copy">
              {intl.formatMessage({ id: "landing.brandingCopy" })}
            </p>
            <Link
              to="/experience"
              className="tc ttu frank f3 fw3 mv5 br2 bg-sec-color ph4 pv3 white hover-white hover-bg-prim-color db shadow-5"
              style={{ width: "28rem" }}
            >
              {intl.formatMessage({ id: "landing.brandingLink" })}
            </Link>
          </div>
          <div className="z-1">
            <img
              src="/images/business_man.jpg"
              alt="Un uomo si sistema la cravatta.Simboleggia una migliore comunicazione con i clienti"
              className="dn di-l h-100 w-100"
            />
          </div>
        </div>
      </div>
      <div className="center mw7 mw9-l mt6 mt3-l ph3">
        <h3 className="dib frank ttu fw4 f4 mv4 tracked-mega">
          {intl.formatMessage({ id: "landing.personalTitle" })}
        </h3>
        <h2 className="mv2 playfair fw4 f2 measure lh-title">
          {intl.formatMessage({ id: "landing.personalSubtitle1" })}
          <br />
          {intl.formatMessage({ id: "landing.personalSubtitle2" })}
        </h2>
        <p className="playfair f3 mv4 measure lh-copy">
          {intl.formatMessage({ id: "landing.personalCopy" })}
        </p>
        <Link
          to="/custom"
          className="tc ttu frank f3 fw3 br2 bg-sec-color ph4 pv3 white hover-white hover-bg-prim-color db shadow-5"
          style={{ width: "28rem" }}
        >
          {intl.formatMessage({ id: "landing.personalLink" })}
        </Link>
        <div className="relative mt5 mt0-l" style={{ height: "24rem" }}>
          <div className="absolute w-80 w-60-m w-40-l left-0 first-tile">
            <img
              src="/images/logins/industrial.png"
              alt="Schermata di login con tema industriale"
              className="shadow-5"
            />
          </div>
          <div className="absolute w-80 w-60-m w-40-l z-1 second-tile">
            <img
              src="/images/logins/classic.png"
              alt="Schermata di login con tema classico"
              className="shadow-5"
            />
          </div>
          <div className="absolute w-80 w-60-m w-40-l z-2 right-0 top-0">
            <img
              src="/images/logins/modern.png"
              alt="Schermata di login con tema moderno"
              className="shadow-5"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-column flex-row-l relative mw9 center pt5-l mt5">
        <h2
          className="absolute-l tc f2 f1-ns fw4 playfair pt5-l mt4"
          style={{ top: "0", left: "0", right: "0" }}
        >
          {intl.formatMessage({ id: "landing.securityTitle" })}
        </h2>
        <div className="w-100 w-50-l pt6-l">
          <img
            src="/images/screen_mockup.png"
            alt="Schermata principale di Time2Client come appare su un pc desktop"
            className="ph0-l ph5"
          />
        </div>
        <div className="w-100 w-50-l pt6-l ph2 ph6-m ph0-l bg-light-color">
          <div className="mt5 measure">
            <ul className="list">
              <li className="mv5 flex">
                <span className="mr3 sec-color">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </span>
                <h3 className="dib mv0 fw4 frank blue-color f3">
                  {intl.formatMessage({ id: "landing.securityPoint1" })}
                </h3>
              </li>
              <li className="mv5 flex">
                <span className="mr3 sec-color">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </span>
                <h3 className="dib mv0 fw4 frank blue-color f3">
                  {intl.formatMessage({ id: "landing.securityPoint2" })}
                </h3>
              </li>
              <li className="mv5 flex">
                <span className="mr3 sec-color">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </span>
                <h3 className="dib mv0 fw4 frank blue-color f3">
                  {intl.formatMessage({ id: "landing.securityPoint3" })}
                </h3>
              </li>
              <li className="mv5 flex">
                <span className="mr3 sec-color">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </span>
                <h3 className="dib mv0 fw4 frank blue-color f3">
                  {intl.formatMessage({ id: "landing.securityPoint4" })}
                </h3>
              </li>
            </ul>
          </div>
          <div className="center mw6">
            <Link
              to="/technology"
              className="tc ttu frank f3 fw3 mv5 br2 bg-sec-color ph4 pv3 white hover-white hover-bg-prim-color db shadow-5"
            >
              {intl.formatMessage({ id: "landing.securityLink" })}
            </Link>
          </div>
        </div>
      </div>
      <div
        className="relative social-proof-cover mv6"
        style={{ backgroundColor: "#2b2928" }}
      >
        <span
          className="playfair tc ph4 dib sec-color absolute mv0"
          style={{ fontSize: "18em", top: "-9rem", left: "0", right: "0" }}
        >
          “
        </span>
        <div className="mw9 center pt3">
          <h2 className="dib white frank ttu fw4 f4 ph3 mv4 tracked-mega tc">
            {intl.formatMessage({ id: "landing.socialTitle" })}
          </h2>
        </div>
        <div className="flex flex-column-reverse flex-row-l mw9 mb6 center ph0 pv4 pa4-ns">
          <div className="w-100 w-third-l mt4-l">
            <div className="flex flex-column">
              <div className="pt4 tc">
                <img
                  src="/images/banchieri.jpg"
                  className="br-100 w4 dib"
                  alt="Foto profilo dell'avvocato Marco Banchieri"
                />
              </div>
              <div className="mv1 ml4">
                <h6 className="mv0 pv4 f4 frank fw4 white tc">
                  Marco Banchieri
                </h6>
                <p className="white tc tl-l fw4 f5 frank pv0 mv0">
                  {intl.formatMessage({ id: "landing.socialLine1" })}
                </p>
                <p className="white tc tl-l fw4 f5 frank pv0 mv0">
                  {intl.formatMessage({ id: "landing.socialLine2" })}
                </p>
                <p className="white tc tl-l fw4 f5 frank pv0 mv0">
                  {intl.formatMessage({ id: "landing.socialLine3" })}
                </p>
              </div>
            </div>
          </div>
          <div className="w-100 w-two-thirds-l mb5-l">
            <p className="playfair white fw4 f4 f2-m f1-l mv2 mv5-l mh0 mh4-ns ph4">
              {intl.formatMessage({ id: "landing.socialCopy" })}
            </p>
          </div>
        </div>
      </div>
      <div id="prezzi" className="center mw9 mv4 tc">
        <h2 className="dib frank ttu fw4 f4 ph3 ph0-l mv4 tracked-mega tc">
          {intl.formatMessage({ id: "landing.pricingTitle" })}
        </h2>
        <h2 className="playfair fw4 f2 f1-ns ph3">
          {intl.formatMessage({ id: "landing.pricingSubtitle" })}
        </h2>
      </div>
      <div className="center mw7 mb5">
        <table className="collapse">
          <thead>
            <tr>
              <th className="f3 fw4 frank pv3" style={{ width: "310px" }}></th>
              <th
                className="f3 fw4 frank pv3 sec-color"
                style={{ width: "310px" }}
              >
                {intl.formatMessage({ id: "landing.pricingTrial" })}
              </th>
              <th
                className="f3 fw4 frank pv3 sec-color"
                style={{ width: "310px" }}
              >
                Standard
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="pv3 bt bb br b--sec-color">
                <span className="dib frank fw4 f4 pa3">Clienti</span>
              </td>
              <td className="pv3 ba b--sec-color v-mid tc">1 Cliente</td>
              <td className="pv3 ba b--sec-color v-mid tc">illimitati</td>
            </tr>
            <tr>
              <td className="pv3 bt bb br b--sec-color">
                <span className="dib frank fw4 f4 pa3">Spazio sul cloud</span>
              </td>
              <td className="pv3 ba b--sec-color v-mid tc">10 documenti</td>
              <td className="pv3 ba b--sec-color v-mid tc">illimitato</td>
            </tr>
            <tr>
              <td className="pv3 bt bb br b--sec-color">
                <span className="dib frank fw4 f4 pa3">Datarooms</span>
              </td>
              <td className="pv3 ba b--sec-color v-mid tc">1 Dataroom</td>
              <td className="pv3 ba b--sec-color v-mid tc">illimitate</td>
            </tr>
            <tr>
              <td className="pv3 bt bb br b--sec-color">
                <span className="dib frank fw4 f4 pa3">
                  Prezzo (IVA esclusa*)
                </span>
              </td>
              <td className="pv3 ba b--sec-color v-mid tc">
                <span className="frank f3 f2-ns fw3">0 € / mese</span>
              </td>
              <td className="pv3 ba b--sec-color v-mid tc">
                <span className="frank f3 f2-ns fw3">15 € / mese</span>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="frank f5 ph3">* Dove applicabile</p>
      </div>
      <div className="relative pv5 pv6-ns mt7 bg-light-color magazine-cover">
        <div className="mw9 pt3 ph3 tc-ns center">
          <p className="measure-wide center f3 f2-l mv0 pv0 playfair">
            Scopri il nostro blog. Cosa c'è dietro alla tecnologia di
            Time2Client e quali sono i nostri obiettivi e strategie future.
          </p>
        </div>
        <div className="center mw6">
          <a
            href="https://blog.time2client.com"
            className="tc ttu frank f3 fw3 mv5 br2 bg-sec-color ph4 pv3 white hover-white hover-bg-prim-color db shadow-5"
          >
            Il nostro Magazine
          </a>
        </div>
      </div>
      <div
        className="relative pv5 bg-light-color footer-cover"
        style={{ backgroundColor: "#2b2928" }}
      >
        <Register />
      </div>
    </Layout>
  )
}

export default IndexPage
