import React, { useState } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

const Register = () => {
  const [email, setEmail] = useState("")

  const { executeRecaptcha } = useGoogleReCaptcha()

  const [formSent, setFormSent] = useState(false)
  const [formError, setFormError] = useState(null)

  const handleSubmit = async e => {
    e.preventDefault()
    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    const token = await executeRecaptcha("registration")

    const data = { email, token }

    fetch("https://app.time2client.com/api/new-account", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(response => response.json()) // parse JSON from request
      .then(resultData => {
        if (!resultData.received) {
          setFormError("Server error")
        }
        setFormSent(true)
      })
      .catch(error => {
        console.error(error)
        setFormSent(true)
        setFormError(error)
      })
  }

  return (
    <>
      {formSent ? (
        <div className="bg-sec-color white mw7 center pa4 br2-ns ba b--black-10">
          {formError ? (
            <div className="tc">
              <h3 className="f2 lato fw4">Errore nell'invio del form</h3>
              <p className="red">{JSON.stringify(formError)}</p>
            </div>
          ) : (
            <div className="tc">
              <h3 className="f2 lato fw4">Grazie per la registrazione.</h3>
              <p>A breve riceverà una mail per confermare il suo account.</p>
            </div>
          )}
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="bg-sec-color white mw7 center pa4 br2-ns ba b--black-10"
          method="post"
        >
          <fieldset className="cf bn ma0 pa0">
            <legend className="frank pa0 f5 f4-ns mb3">
              Voglio provare Time2Client
            </legend>
            <div className="cf">
              <label className="clip" htmlFor="lawyer_email">
                Email
              </label>
              <input
                className="f6 f5-l input-reset bn fl black-80 bg-white pa3 lh-solid w-100 w-75-m w-80-l br2-ns br--left-ns"
                placeholder="avvocato@studiolegale.it"
                type="email"
                value={email}
                onChange={e => {
                  setEmail(e.target.value)
                }}
              />
              <button
                className="frank f5 f4-l button-reset fl pv3 tc bn bg-animate bg-black-70 hover-bg-prim-color white pointer w-100 w-25-m w-20-l br2-ns br--right-ns"
                type="submit"
              >
                Invia
              </button>
            </div>
            <p className="frank f5 pt3"> </p>
          </fieldset>
        </form>
      )}
    </>
  )
}

export default Register
